<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:headers="headers"
								:items="items"
								item-key="id"
								:server-items-length="totalItems"
								:options.sync="options"
								:footer-props="{'items-per-page-options':[25,50]}"
								:search="search"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" />
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.action`]="{ item }">
								<template v-if="item.status === 'new'">
									<div @click="approveClaim(item)" style="cursor:pointer;" class="green--text mx-1"><svg-icon icon="check-circle" /></div>
									<div @click="rejectClaim(item)" style="cursor:pointer;" class="red--text mx-1"><svg-icon icon="cross-circle" /></div>
								</template>
							</template>
							<template v-slot:[`item.date_created`]="{ item }">
								<span class="text-no-wrap">{{ dayjs(item.date_created).format(`YYYY-MM-DD`) }}</span>
							</template>
							<template v-slot:[`item.status`]="{ item }">
								<svg-icon class="green--text" v-if="item.status === `approved`" icon="check-circle" />
								<svg-icon class="red--text" v-else-if="item.status === `rejected`" icon="cross-circle" />
								<svg-icon class="red--text" v-else-if="item.status === `disconnected`" icon="broken-link" />
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</v-container>

</template>

<script>
	import { globalMixIn } from '@/App.vue'

	export default {
		data: () => ({
			options: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			search: ``,
			totalItems: 0,
			headers: [
				{ text: ``, value: `status` },
				{ text: `E-mail`, value: `email` },
				{ text: `Phone`, value: `phone` },
				{ text: `Claim`, value: `public_account_id` },
				{ text: `Motivation`, value: `motivation` },
				{ text: `Date`, value: `date_created` },
				{ text: `Actions`, value: `action`, align: `center`, sortable: false },
			],
			items: [],
		}),
		methods: {
			refreshContent() {
				this.$httpInt.get(`/v2/app/claims`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
			},
			approveClaim (item) {
				this.$httpInt.post(`/v2/app/legacy/admin/claims/approve-claim`, {
					email: item.email,
					public_account_id: item.public_account_id
				})
					.then(() => {
						this.$root.$snackbar(`Approved! A welcome e-mail has been sent to ${item.email}`)
						this.refreshContent()
					})
			},
			rejectClaim (item) {
				this.$httpInt.post(`/v2/app/legacy/admin/claims/reject-claim`, {
					email: item.email,
					public_account_id: item.public_account_id,
					reject_reason: ``
				})
					.then(() => this.refreshContent())
			},
		},
		mixins: [globalMixIn],
		mounted () {
			this.refreshContent()
		},
		name: `CommunityClaims`,
		watch: {
			search: {
				handler() {
					this.$debounce(() => this.refreshContent(), 300)
				},
			},
			options: {
				handler() {
					this.refreshContent()
				},
			},
		},
	}
</script>